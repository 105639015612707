<template>
  <div class="app-wrapper h-screen w-screen flex flex-col overflow-hidden bg-n-87">
    <div class="flex-grow overflow-auto p-10 flex items-center justify-center relative">
      <v-card class="auth-container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          src="../assets/images/SHEL.svg"
          alt="Logo"
          class="h-60"
        >
        <slot />
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.app-wrapper {
	max-width: 2500px;
	padding: 0;
}
</style>
